import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { User } from 'src/app/data/data';
import { DataManagementService } from '../../data-management/data-management.service';

@Component({
  selector: 'ngx-history-user',
  templateUrl: './history-user.component.html',
  styleUrls: ['./history-user.component.scss'],
})
export class HistoryUserComponent implements OnInit {

  checked = true;
  title = 'appBootstrap';
  closeResult: string;

  settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    columns: {
      date: {
        title: 'Date',
        type: 'date',
        filter: true,
        valuePrepareFunction: time => {
          let newValue = this.datePipe.transform(time, 'dd/MM/yyyy HH:mm:ss');
          return newValue;
        }
      },
      status: {
        title: 'Status',
        filter: {
          type: 'list',
          config: {
            selectText: 'Tous...',
            list: [
              { value: '0', title: 'Programme' },
              { value: '1', title: 'En cours' },
              { value: '2', title: 'Termine' },
            ],
          },
        },
        type: 'html',
        valuePrepareFunction: (value) => {
          if (value == 1) {
            return '<div class="text-nowrap text-info"><i class="fas fa-check-circle"> </i> En cours </div>';
          } else if (value == 2) {
            return '<div class="text-nowrap text-error"><i class="fas fa-check-circle"> </i> Termine </div>';
          } else {
            return '<div class="text-nowrap text-warning"><i class="fa fa-exclamation-circle"></i> Programme</div>';
          }
        },
      },
      username: {
        title: 'Technicien',
        type: 'string',
        filter: true
      },
      name: {
        title: 'Ascenseur',
        type: 'number',
        filter: true,
        valuePrepareFunction: (value) => {
          console.log(value);
          return value ? value.substring(0, value.lastIndexOf('/')) : value;
        }
      },
      description: {
        title: 'Description',
        type: 'number',
        filter: true
      },
      type: {
        title: 'Type',
        type: 'number',
        filter: true
      },
    }
  };
  source: LocalDataSource = new LocalDataSource();
  loading: boolean;
  customRanges: any;
  selectedUser: User = new User();
  constructor(private datePipe: DatePipe, public dataManagementService: DataManagementService) {
    this.dataManagementService.init();
  }

  ngOnInit(): void {
    this.refresh();
  }

 refresh() {
    this.loading = true;
    this.dataManagementService.maintenanceDateDtos = [];
    this.dataManagementService.findAllMaintenancesByUser(this.selectedUser.id).subscribe(maintenanceDateDto => {

      maintenanceDateDto.forEach(element => {
        element.username = this.dataManagementService.getUserIdByNameUser(element.userId)
      });

      this.dataManagementService.maintenanceDateDtos = maintenanceDateDto;

      this.source.load(this.dataManagementService.maintenanceDateDtos);
      this.loading = true;
    }, () => {
      this.loading = false;
    })
  }

  chooseUser(user) {
    this.selectedUser = user;

  }

}
