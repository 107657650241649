<div class="width-35 ">
    <nb-card class="h-100">
        <nb-card-body class="d-flex flex-row justify-content-between align-items-center">
            <lib-daterangepicker [(start)]="start" [(end)]="end" format="MM-DD-YYYY" opens="left" complete="refresh()"
                [pill]="true" [ranges]="customRanges"></lib-daterangepicker>
            <button nbButton style="background-color: #ec1c24; color: #ffffff;" (click)="refresh()" nbSpinnerStatus="warning">Recherche</button>
        </nb-card-body>
    </nb-card>
</div>

<nb-card status="danger">
    <nb-card-header style="color: #ffffff; text-align: center; font-size: 30px;background-color: #ec1c24;">
        Historique
    </nb-card-header>

    <nb-card-body>
        <ng2-smart-table [settings]="settings" [source]="source">
        </ng2-smart-table>
    </nb-card-body>
</nb-card>