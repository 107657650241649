<div class="width-50 ">
    <nb-card>
        <nb-card-body>

            <div class="row">
                <div class="col-md-6">

                    <nb-select status="danger" [(selected)]="selectedLift" class=" pointer w-100">
                        <nb-option *ngFor="let lift of dataManagementService?.lifts" [value]="lift">{{dataManagementService.formatLiftName(lift)}}
                        </nb-option>
                    </nb-select>
                </div>

                <div class="col-md-6" style="text-align: right;">
                    <button nbButton class=" pointer w-50 " style="background-color: #ec1c24; color: #ffffff;"
                        nbSpinnerStatus="warning" (click)="refresh()" nbSpinnerStatus="warning">Recherche</button>

                </div>
            </div>

        </nb-card-body>
    </nb-card>
</div>

<nb-card status="danger">
    <nb-card-header style="color: #ffffff; text-align: center; font-size: 30px; background-color: #ec1c24;">
        Historique par Ascenseur
    </nb-card-header>

    <nb-card-body>
        <ng2-smart-table [settings]="settings" [source]="source">
        </ng2-smart-table>
    </nb-card-body>
</nb-card>
